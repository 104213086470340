import React from "react";
import {AppBar, Box, Button, ButtonGroup, Toolbar, Typography,} from "@mui/material";
import {AuthenticationContext} from "./AuthenticationContext";
import LogoutIcon from "@mui/icons-material/Logout";


export const Registered: React.FC = () => {
    const userContext = React.useContext(AuthenticationContext)
    const user = userContext.user()

    return (
        <Box sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <AppBar position='static'>
                <Toolbar>
                    <Box sx={{flexGrow: 1}}/>
                    <Typography variant="h5">{user.email}</Typography>
                    &nbsp;&nbsp;
                    <ButtonGroup>
                        <Button onClick={userContext.logOut}><LogoutIcon/></Button>
                    </ButtonGroup>
                </Toolbar>
            </AppBar>
            <Box sx={{padding: 10, flexGrow: 2}}>
                <Typography variant="h3" align="center">
                    Twoje konto zostało zarejestrowane przy użyciu adresu "{user.email}". Skontaktuj się z terapeutą podając ten adres w celu aktywacji konta.
                </Typography>
            </Box>
        </Box>
    )
}