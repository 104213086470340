import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {UserContextProvider} from "./AuthenticationContext";
import {plPL} from "@mui/material/locale";
import {ReadingRoutes} from "./ReadingRoutes";
import {DevSupport} from "@react-buddy/ide-toolbox";
import {ComponentPreviews, useInitial} from "./dev";
import {
    experimental_extendTheme as materialExtendTheme,
    Experimental_CssVarsProvider as MaterialCssVarsProvider,
    THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles';
import {CssVarsProvider as JoyCssVarsProvider} from '@mui/joy/styles';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const materialTheme = materialExtendTheme({
        //palette selector https://mui.com/material-ui/customization/color/
        colorSchemes: {
            light: {
                palette: {
                    primary: {
                        light: '#4b9fea',
                        main: '#1e88e5',
                        dark: '#155fa0',
                        contrastText: '#fff',
                    },
                    secondary: {
                        light: '#ff4569',
                        main: '#ff1744',
                        dark: '#b2102f',
                        contrastText: '#000',
                    },
                }
            }
        },
        components: {
            MuiAppBar: {
                styleOverrides: {
                    colorPrimary: {
                        backgroundColor: "white"
                    }
                }
            }
        }
    },
    plPL,);
root.render(
    <MaterialCssVarsProvider theme={{[MATERIAL_THEME_ID]: materialTheme}}>
        <JoyCssVarsProvider>
            <UserContextProvider>
                <DevSupport ComponentPreviews={ComponentPreviews}
                            useInitialHook={useInitial}
                >
                    <ReadingRoutes/>
                </DevSupport>
            </UserContextProvider>
        </JoyCssVarsProvider>
    </MaterialCssVarsProvider>
);
