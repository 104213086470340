import React from "react";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup, Switch} from "@mui/material";

interface SessionRestartDialogProps {
    open: boolean
    onRestart: () => void
    onCancel: () => void
}

export const SessionRestartDialog: React.FC<SessionRestartDialogProps> = (props) => {
    return (
        <Dialog
            open={props.open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Połączenie z serwerem zostało utracone!
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Połączyć ponownie i wznowić czytankę?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onCancel}>Zakończ czytankę</Button>
                <Button onClick={props.onRestart} autoFocus>Połącz ponownie</Button>
            </DialogActions>
        </Dialog>
    )
}