import React from "react";
// @ts-ignore
import firebaseui from 'local/npm__pl';
import {initializeApp} from "firebase/app"
import * as firebase from "firebase/auth"
import "firebaseui/dist/firebaseui.css";
import {ApplicationUser, UserRole} from "./AuthenticationContext";
import {IdTokenResult} from "@firebase/auth";
import {Box, Paper, Typography} from "@mui/material";
import LockIcon from '@mui/icons-material/Lock';

const firebaseConfig = {
    apiKey: "AIzaSyCGyIkNY7Lsfv-lxnHVzXJ1Mh1jW-WQluE",
    authDomain: process.env.REACT_APP_AUTH_DOMAIN, //https://cloud.google.com/identity-platform/docs/web/redirect-best-practices#update-authdomain
};

interface Props {
    setUser: (user: ApplicationUser | null) => void
}

export const Login: React.FC<Props> = (props) => {
    const root = React.useRef<HTMLDivElement>(null)
    const realLogin = () => {
        const app = initializeApp(firebaseConfig);
        const ui = firebaseui.auth.AuthUI.getInstance()
            || new firebaseui.auth.AuthUI(firebase.initializeAuth(app));
        if (root.current != null) {
            ui.start(root.current, {
                callbacks: {
                    signInSuccessWithAuthResult: (authResult: any) => {
                        const user = authResult.user
                        console.log(`User authenticated id: ${user.uid}`)
                        user.getIdTokenResult(false).then((idTokenResult: IdTokenResult) => {
                                const roles: UserRole[] = idTokenResult.claims.roles ?? []
                                return props.setUser({
                                        email: user.email,
                                        emailVerified: user.emailVerified,
                                        uid: user.uid,
                                        roles,
                                        getIdToken(forceRefresh?: boolean): Promise<string> {
                                            return user.getIdToken(forceRefresh).catch((error: any) => {
                                                console.log("Error getting a token, re-login required!", error)
                                                props.setUser(null)
                                            })
                                        },
                                        hasAnyRole(...role: UserRole[]): Boolean {
                                            return Boolean(roles.find(value => role.includes(value)))
                                        },
                                    }
                                )
                            }
                        )
                        return false
                    }
                },
                signInOptions: [
                    firebase.GoogleAuthProvider.PROVIDER_ID,
                    {
                        provider: firebase.EmailAuthProvider.PROVIDER_ID,
                        requireDisplayName: false,
                        disableSignUp: {
                            status: true
                        }
                    },
                ]
            })
        }
    }
    const fakeLogin = () => props.setUser({
        email: "user@acme.com",
        emailVerified: false,
        uid: "fake-uid",
        roles: [],
        getIdToken(forceRefresh?: boolean): Promise<string> {
            return Promise.resolve("fake-id-token");
        },
        hasAnyRole(...role: UserRole[]): Boolean {
            return false;
        },
    })

    React.useEffect(process.env.REACT_APP_FAKE_USER === "true" ? fakeLogin : realLogin, [])
    return (
        <Paper elevation={4} sx={{
            width: 1 / 3,
            minWidth: 400,
            minHeight: 500,
            height: 1 / 3,
            padding: 2,
            mx: 'auto',
            marginTop: 3,
            display: "flex",
            flexDirection: "column"
        }}>
            <Typography align="center" fontFamily="Open Sans" sx={{padding: 3}}>
                <LockIcon/><br/> Zaloguj się lub utwórz nowe konto
            </Typography>
            <Box sx={{flexGrow: 1}}>
                <div ref={root}/>
            </Box>
            <Box alignSelf={"center"}><a href="https://naszeczytanki.pl"><img src="/czytanki-primary.svg" alt="logo" height={28}/></a></Box>
        </Paper>
    )

}