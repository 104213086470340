import React, {useEffect, useState} from "react";
import {
    Box,
    CircularProgress, Link,
} from "@mui/material";
import {DataGrid, GridActionsCellItem, GridColDef, GridToolbarContainer, GridToolbarExport} from "@mui/x-data-grid";
import {TraineeContext} from "./TraineeContext";
import ViewListIcon from "@mui/icons-material/ViewList";
import {useNavigate} from "react-router-dom";
import DefaultAppBar from "../DefaultAppBar";
import {GridValueFormatterParams} from "@mui/x-data-grid/models/params/gridCellParams";
import {format} from 'date-fns'
import {Trainee} from "./TraineeSelector";
import {SessionEdit} from "./SessionEdit";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EditIcon from '@mui/icons-material/Edit';
import {GridActionsColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import {GridRowParams} from "@mui/x-data-grid/models/params/gridRowParams";
import {AuthenticationContext} from "../AuthenticationContext";

export interface SessionItem {
    id: string,
    reading: string,
    start: string,
    duration: number,
    hyphens: number,
    hyphensPerMinute: number,
    words: number,
    wordsPerMinute: number,
    grade: number,
    notes: string,
    dashboardUrl: string | undefined
}

function ExportToolbar(trainee: Trainee): React.JSXElementConstructor<any> {
    return () => (
        <GridToolbarContainer>
            <GridToolbarExport csvOptions={{fileName: `${trainee.name}-${format(new Date(), 'yyyMMddHHmmss')}`}}/>
        </GridToolbarContainer>
    );
}

function formatDuration(duration: number) {
    const minutes = Math.floor(duration / 60000)
    const seconds = String(Math.floor((duration % 60000) / 1000)).padStart(2, '0')
    return `${minutes}:${seconds}`
}

export const SessionsList: React.FC = () => {
    const userContext = React.useContext(AuthenticationContext)
    const trainee = React.useContext(TraineeContext)

    const [sessions, setSessions] = useState<SessionItem[]>([])
    const navigate = useNavigate()
    const [editBuffer, setEditBuffer] = useState<SessionItem>()
    const clearEditBuffer = () => setEditBuffer(undefined)

    useEffect(() => {
        userContext.client().get<{ sessions: SessionItem[] }>(`trainees/${trainee.trainee().id}/sessions`).then(({data}) => {
            setSessions(data.sessions)
        })
    }, [userContext, trainee])

    const columns: (GridColDef | GridActionsColDef)[] = [
        {
            field: 'start',
            headerName: 'Data',
            minWidth: 200,
            valueFormatter: (params: GridValueFormatterParams<string>) => format(new Date(params.value), 'yyyy-MM-dd HH:mm')
        },
        {
            field: 'duration', headerName: 'Czas',
            minWidth: 20,
            align: 'right',
            valueFormatter: (params: GridValueFormatterParams<number>) => formatDuration(params.value)
        },
        {field: 'reading', headerName: 'Tytuł', minWidth: 200},
        {field: 'hyphens', headerName: 'Sylab'},
        {
            field: 'hyphensPerMinute',
            headerName: 'Sylab/min',
            valueFormatter: (params: GridValueFormatterParams<number>) => Math.round(params.value * 10) / 10
        },
        {field: 'words', headerName: 'Słów'},
        {
            field: 'wordsPerMinute',
            headerName: 'Słów/min',
            valueFormatter: (params: GridValueFormatterParams<number>) => Math.round(params.value)
        },
        {field: 'grade', headerName: "Ocena"},
        {
            field: 'notes',
            headerName: "Notatki",
            flex: 2
        },
        {
            field: 'actions',
            type: 'actions',
            width: 80,
            getActions: (params: GridRowParams<SessionItem>) => {

                const actions = [
                    <GridActionsCellItem
                        icon={<EditIcon/>}
                        label="Edytuj"
                        onClick={() => setEditBuffer(params.row)}
                    />,
                    <GridActionsCellItem
                        icon={<DeleteForeverIcon/>}
                        label="Usuń"
                        onClick={() => console.log(params)}
                        showInMenu
                    />]
                if (params.row.dashboardUrl) actions.push(
                    <GridActionsCellItem
                        icon={<DashboardIcon/>}
                        label="Dashboard"
                        onClick={() => window.open(params.row.dashboardUrl)}
                        showInMenu
                    />)
                return actions
            },
        },
    ];

    const content = sessions ?
        <DataGrid pageSize={100} density='compact'
                  rows={sessions}
                  columns={columns}
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  components={{Footer: ExportToolbar(trainee.trainee())}}
        /> :
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <CircularProgress/>
        </Box>

    return (
        <Box sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <DefaultAppBar>
                <Link underline="hover" component="button" onClick={() => navigate("/")}>Powrót</Link>
            </DefaultAppBar>
            <Box sx={{padding: 1, flexGrow: 2}}>
                {editBuffer && <SessionEdit sessionId={editBuffer.id} onClose={clearEditBuffer} onSave={data => {
                    setSessions(prev => prev.map(session => session.id === data.id ? {...session, ...data} : session))
                    clearEditBuffer()
                }}/>}
                {content}
            </Box>
        </Box>
    )
}