import React, {useEffect, useState} from "react";
import {MenuItem, TextField} from "@mui/material";
import {MicrophoneDevice} from "./MicCapture";

const loadDevices = () => navigator.mediaDevices
    .enumerateDevices()
    .then(devInfos => devInfos.filter(info => info.kind === "audioinput"))

export interface MicSelectorProps {
    onMicChange: (mic: MicrophoneDevice) => void
    microphone: MicrophoneDevice
}

export const MicSelector: React.FC<MicSelectorProps> = (props) => {
    const [devices, setDevices] = useState<MediaDeviceInfo[]>([])

    const deviceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let selectedItem = event.target.value
        if (selectedItem) props.onMicChange({id: selectedItem, label: event.target.name})
    }

    useEffect(() => {
        loadDevices().then(setDevices)
    }, []);

    return (
        <TextField
            select
            label="Źródło dźwięku"
            value={props.microphone.id}
            onChange={deviceChange}
        >
            {devices.map((device, idx) => <MenuItem key={idx} value={device.deviceId}>{device.label}</MenuItem>)}
            <MenuItem value='default'>Domyślne</MenuItem>
        </TextField>
    )
};
