import React, {useEffect} from "react";
import {Subject} from "rxjs";

const quantumSize = 128

interface VolumeEvent {
    type: "volume"
    volume: number
}

const loadPCMWorker = (audioContext: AudioContext) =>
    audioContext.audioWorklet.addModule('/processor.js')
        .catch(error => console.log("Error loading PCM worker script", error))

const getMediaStream = (microphone: MicrophoneDevice) =>
    //https://developer.mozilla.org/en-US/docs/Web/API/MediaStream
    //https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices
    //https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
    //https://developer.mozilla.org/en-US/docs/Web/API/MediaTrackConstraints
    //https://w3c.github.io/mediacapture-main/#constrainable-properties
    //https://stackoverflow.com/questions/62392352/mediadevices-getusermedia-how-can-i-set-audio-constraints-sampling-rate-bit-d
    navigator.mediaDevices.getUserMedia({
        audio: {
            //https://cloud.google.com/speech-to-text/docs/best-practices-provide-speech-data
            autoGainControl: false, //https://w3c.github.io/mediacapture-main/#dfn-autogaincontrol
            echoCancellation: false, //https://w3c.github.io/mediacapture-main/#dfn-echocancellation
            noiseSuppression: false, //https://w3c.github.io/mediacapture-main/#dfn-noisesuppression
            latency: 0, //https://w3c.github.io/mediacapture-main/#dfn-latency
            deviceId: microphone.id, //https://w3c.github.io/mediacapture-main/#dfn-deviceid
            // sampleRate: 16000, //https://w3c.github.io/mediacapture-main/#dfn-samplerate
            sampleRate: { //https://w3c.github.io/mediacapture-main/#dfn-samplerate
                exact: 48000
            },
            sampleSize: { ////https://w3c.github.io/mediacapture-main/#dfn-samplesize
                exact: 16
            },
            channelCount: { //https://w3c.github.io/mediacapture-main/#dfn-channelcount
                exact: 1
            }
        },
        video: false
    })

const capture = (sampleRate: number, audioContext: AudioContext, stream: MediaStream, output: (data: any) => void, onVolume: Subject<number>) => {
    //https://developer.mozilla.org/en-US/docs/Web/API/AudioContext/createMediaStreamSource
    const source: MediaStreamAudioSourceNode = audioContext.createMediaStreamSource(stream)
    console.log("Source sample rate: ", source.mediaStream.getTracks()[0].getSettings().sampleRate)
    //frame time is (render quantum size (const 128) * quantaPerFrame)/sample rate
    const expectedFrameDuration = 0.1
    const quantaPerFrame = Math.floor((expectedFrameDuration * sampleRate) / quantumSize)
    const frameDuration = (quantumSize * quantaPerFrame) / sampleRate
    console.log("Sample rate is ", sampleRate, " processing ", quantaPerFrame, " quanta per frame, effective frame duration is ", frameDuration)
    const pcmWorker = new AudioWorkletNode(audioContext, 'pcm-processor', {
        outputChannelCount: [1],
        processorOptions: {
            quantaPerFrame
        }
    })
    pcmWorker.port.onmessage = (event: MessageEvent<any>) => {
        if (event.data?.type === "volume") {
            onVolume.next(event.data.volume)
        } else {
            try {
                output(event.data)
            } catch (error) {
                console.log("Error sending data", error)
            }
        }
    }
    pcmWorker.port.start()
    source.connect(pcmWorker)
}

const createAudioContext = (sampleRate: number): AudioContext => new window.AudioContext({sampleRate})

export interface MicrophoneDevice {
    id: string,
    label: string
}

interface MicrophoneProps {
    output?: (data: Int16Array) => void
    microphone: MicrophoneDevice
    onVolume: Subject<number>
}

export const defaultMicrophone = {id: "default", label: "Default"}

export const MicCapture: React.FC<MicrophoneProps> = (props) => {

    useEffect(() => {
        if (props.output) {
            const output = props.output
            console.log("Creating audio context")
            const startCapture = async () => {
                const stream = await getMediaStream(props.microphone)
                //as of 2023-03-06 firefox doesn't provide sampleRate, it's fixed to 48000
                //https://developer.mozilla.org/en-US/docs/Web/API/MediaTrackSettings#browser_compatibility
                const sampleRate = stream.getTracks()[0].getSettings().sampleRate ?? 48000
                const audioContext = createAudioContext(sampleRate)
                await loadPCMWorker(audioContext)
                capture(sampleRate, audioContext, stream, output, props.onVolume)
                return () => {
                    console.log("Closing audio context")
                    stream.getTracks().forEach(track => track.stop())
                    audioContext.close()
                }
            }
            const handle = startCapture()
            return () => {
                handle.then(close => close())
            }
        }
    }, [props.output, props.microphone])

    return null
};