import React, {useEffect, useState} from "react";
import {SelectTrainee, Trainee} from "./TraineeSelector";
import {Box, Button, Dialog, DialogContent, DialogTitle, FormControl, TextField} from "@mui/material";
import * as _ from "lodash";
import {SessionConfigDialog} from "./SessionConfigDialog";
import {SessionConfig} from "../reading/Messaging";
import {SessionConfigVersioned} from "../reading/Reading";
import {AuthenticationContext} from "../AuthenticationContext";


interface Context {
    trainee(): Trainee

    close(): void

    editProfile(): void
}

const invalidContext: Context = {
    trainee: () => { throw Error("Trainee not selected!")},
    close: () => {},
    editProfile: () => { throw Error("Trainee not selected!")},
}

function traineeContext(trainee: Trainee, close: () => void, editProfile: () => void): Context {
    return {
        trainee: () => trainee,
        close,
        editProfile,
    }
}

const TraineeContext = React.createContext<Context>(invalidContext);

interface Props {
    children: React.ReactNode;
}

export interface TraineeSelectionDialogProps {
    onClose: (value: Trainee | undefined) => void;
}

const TraineeSelectionDialog: React.FC<TraineeSelectionDialogProps> = (props: TraineeSelectionDialogProps) => {
    const userContext = React.useContext(AuthenticationContext)
    const isTherapistOrAdmin = userContext.user().hasAnyRole("therapist","admin")

    const [newTrainee, setNewTrainee] = useState('')
    const addTrainee = (newTrainee: string) => {
        userContext.client().post<any, Trainee>("trainees", {name: newTrainee}).then(({data}) => props.onClose(data))
    }
    return (
        <Dialog onClose={() => props.onClose(undefined)} open fullWidth>
            <DialogTitle>Wybierz ucznia</DialogTitle>
            <DialogContent>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 1,
                    gap: 2
                }}>
                    <FormControl fullWidth>
                        <SelectTrainee onTrainee={(trainee) => {
                            props.onClose(trainee)
                        }}></SelectTrainee>
                    </FormControl>
                    {isTherapistOrAdmin && <FormControl fullWidth>
                        <TextField
                            label="Nowy uczeń"
                            value={newTrainee}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setNewTrainee(event.target.value);
                            }}
                        /><Button disabled={_.isEmpty(newTrainee)} onClick={() => addTrainee(newTrainee)}>Dodaj</Button>
                    </FormControl>}
                </Box>
            </DialogContent>
        </Dialog>
    );
}

const TraineeContextProvider: React.FC<Props> = ({children}) => {
    console.log("Rendering trainee context")
    const userContext = React.useContext(AuthenticationContext)
    const [sessionConfig, setSessionConfig] = React.useState<SessionConfigVersioned>()
    const [trainee, setTrainee] = React.useState<Trainee>();
    const [openProfileEditor, setOpenProfileEditor] = React.useState(false);

    const refreshSessionConfig = React.useCallback(
        (trainee: Trainee) => userContext.client().get<SessionConfig>(`trainees/${trainee.id}/default-session-config`)
            .then(sessionConfig => setSessionConfig({
                version: 0,
                sessionConfig: sessionConfig.data
            })), [userContext])

    const updateSessionConfig: (trainee: Trainee, sessionConfig: SessionConfigVersioned) => Promise<void> = React.useCallback(
        (trainee: Trainee, sessionConfig: SessionConfigVersioned) =>
            userContext.client().put<SessionConfig>(`trainees/${trainee.id}/default-session-config`, sessionConfig.sessionConfig).then(() => refreshSessionConfig(trainee))
        , [userContext, refreshSessionConfig])


    useEffect(() => {
        if (trainee) refreshSessionConfig(trainee)
    }, [refreshSessionConfig, trainee]);


    const close = () => setTrainee(undefined)
    console.log(`User is ${trainee}`)

    return trainee ?

        <TraineeContext.Provider value={traineeContext(trainee, close, () => setOpenProfileEditor(true))}>
            <SessionConfigDialog open={openProfileEditor} onClose={() => setOpenProfileEditor(false)}
                                 sessionConfig={{current: sessionConfig, onChange: (sessionConfig) => updateSessionConfig(trainee, sessionConfig)}}/>
            {children}
        </TraineeContext.Provider>
        : <TraineeSelectionDialog onClose={setTrainee}></TraineeSelectionDialog>


};


export {TraineeContextProvider, TraineeContext};