import React, {memo, useEffect, useMemo, useState} from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem, Select,
    Switch,
    TextField
} from "@mui/material";
import {SessionConfigVersioned} from "../reading/Reading";
import {GCPSpeechToTextV1, isGCPV1, OutputMessage, RecognitionProfile, SessionConfig, SessionConfigUpdate} from "../reading/Messaging";
import {Draft, produce} from "immer";
import {MicSelector} from "../reading/MicSelector";
import {MicrophoneDevice} from "../reading/MicCapture";

interface SessionConfigProps {
    current?: SessionConfigVersioned
    onChange: (newSessionConfig: SessionConfigVersioned) => void
}

interface MicrophoneProps {
    current: MicrophoneDevice
    onChange: (mic: MicrophoneDevice) => void
}

interface SessionConfigDialogProps {
    sessionConfig?: SessionConfigProps
    microphone?: MicrophoneProps
    open: boolean
    onClose: () => void
}

export const SessionConfigDialog: React.FC<SessionConfigDialogProps> = memo((props) => {
    const [dirty, setDirty] = useState(true)
    const [currentConfig, setCurrentConfig] = useState<SessionConfigVersioned>()
    useEffect(() => {
        setCurrentConfig(props.sessionConfig?.current)
        setDirty(false)
    }, [props.sessionConfig]);

    const sessionConfigControls = useMemo(() => {
        const sessionConfig = props.sessionConfig
        if (sessionConfig && currentConfig && isGCPV1(currentConfig.sessionConfig)) {
            const save = <T extends RecognitionProfile>(currentConfig: SessionConfigVersioned, draft: (draft: Draft<SessionConfig<T>>) => void) => {
                const newConfig = produce(currentConfig.sessionConfig, draft)
                setDirty(true)
                setCurrentConfig({version: currentConfig.version, sessionConfig: newConfig})
                sessionConfig.onChange({version: currentConfig.version, sessionConfig: newConfig})
            }
            // const sessionConfig = currentConfig.sessionConfig
            const profile = currentConfig.sessionConfig.recognitionProfile
            return <>
                <FormControlLabel
                    control={
                        <Switch checked={currentConfig.sessionConfig.timeLimit}
                                disabled={dirty}
                                onChange={event => save<GCPSpeechToTextV1>(currentConfig, config => {config.timeLimit = event.target.checked})}/>
                    }
                    label="Limit czasu"/>
                <FormControlLabel
                    control={
                        <Switch checked={currentConfig.sessionConfig.onlyUpperCase}
                                disabled={dirty}
                                onChange={event => save<GCPSpeechToTextV1>(currentConfig, config => {config.onlyUpperCase = event.target.checked})}/>
                    }
                    label="Tylko wielkie litery"/>
                <FormControlLabel
                    control={
                        <Switch checked={profile.partialMatch}
                                disabled={dirty}
                                onChange={event => save<GCPSpeechToTextV1>(currentConfig, config => {config.recognitionProfile.partialMatch = event.target.checked})}/>
                    }
                    label="Rozpoznawaie sylab"/>
                <FormControlLabel
                    control={
                        <Switch checked={profile.useSubstitutions}
                                disabled={dirty}
                                onChange={event => save<GCPSpeechToTextV1>(currentConfig, config => {config.recognitionProfile.useSubstitutions = event.target.checked})}/>
                    }
                    label="Akceptowanie podobnych głosek"/>
                <FormControl fullWidth>
                    <FormControlLabel
                        sx={{justifyContent: 'flex-end', gap: '10px'}}
                        label={"Poziom wzmocnienia rozpoznawania mowy"}
                        labelPlacement={"start"}
                        control={<TextField
                            select
                            disabled={dirty}
                            size={"small"}
                            value={profile.boost}
                            onChange={event => {
                                const newValue = Number(event.target.value)
                                save<GCPSpeechToTextV1>(currentConfig, config => {
                                    config.recognitionProfile.boost = newValue;
                                    config.recognitionProfile.modelAdaptation = newValue > 0
                                })
                            }}
                        >
                            {Array(29).fill(0).map((value, idx) => idx > 20 ? (idx - 18) * 10 : idx)
                                .map(value => value === 0 ? <MenuItem key={value} value={value}>Wyłączone</MenuItem> :
                                    <MenuItem key={value} value={value}>{value}</MenuItem>)}
                        </TextField>}
                    />
                </FormControl>
            </>
        }
        return <></>
    }, [currentConfig, dirty, props.sessionConfig])

    const microphoneControls = useMemo(() => {
        const microphone = props.microphone
        return microphone ?
            <FormControl fullWidth>
                <MicSelector onMicChange={microphone.onChange} microphone={microphone.current}/>
            </FormControl>
            :
            <></>

    }, [props.microphone])


    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth>
            <DialogTitle>Parametry sesji</DialogTitle>
            <DialogContent>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 1,
                    gap: 2
                }}>
                    {microphoneControls}
                    {sessionConfigControls}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>Zamknij</Button>
            </DialogActions>
        </Dialog>
    )
})