import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';

import Medals from "./Medals";

import {AppBar, Box, Button, ButtonGroup, LinearProgress, Link, ToggleButton as MuiToggleButton, Toolbar, Tooltip, Typography} from "@mui/material";
import {grey} from '@mui/material/colors';
import SettingsIcon from "@mui/icons-material/Settings";
import Hearing from "@mui/icons-material/Hearing";
import FastForward from "@mui/icons-material/FastForward";
import FastRewind from "@mui/icons-material/FastRewind";
import SkipNext from "@mui/icons-material/SkipNext";
import MicOffIcon from '@mui/icons-material/MicOff';
import SkipPrevious from "@mui/icons-material/SkipPrevious";
import {AuthenticationContext} from "../AuthenticationContext";
import * as Messaging from "./Messaging";
import {
    InputMessage,
    ReadingStats,
    ReadingUpdate,
    RecognitionProfile,
    SessionConfig,
    SessionConfigUpdate,
    SessionInfo,
    TimeSync,
    UnreachableCaseError
} from "./Messaging";
import {defaultMicrophone, MicCapture, MicrophoneDevice} from "./MicCapture";
import {TraineeContext} from "../trainee/TraineeContext";
import {SessionEdit} from "../trainee/SessionEdit";
import {SessionRestartDialog} from "./SessionRestartDialog";
import {SessionConfigDialog} from "../trainee/SessionConfigDialog";
import {ReadingContent} from "./ReadingContent";
import {Subject, throttleTime} from "rxjs";
import SignalCellular1BarRoundedIcon from '@mui/icons-material/SignalCellular1BarRounded';
import SignalCellular2BarRoundedIcon from '@mui/icons-material/SignalCellular2BarRounded';
import SignalCellular3BarRoundedIcon from '@mui/icons-material/SignalCellular3BarRounded';
import SignalCellular4BarRoundedIcon from '@mui/icons-material/SignalCellular4BarRounded';
import SignalWifi1BarRoundedIcon from '@mui/icons-material/SignalWifi1BarRounded';
import SignalWifi2BarRoundedIcon from '@mui/icons-material/SignalWifi2BarRounded';
import SignalWifi3BarRoundedIcon from '@mui/icons-material/SignalWifi3BarRounded';
import SignalWifi4BarRoundedIcon from '@mui/icons-material/SignalWifi4BarRounded';

const play = (buffer: Blob) => {
    let ctx = new AudioContext()
    buffer.arrayBuffer()
        .then(data => ctx.decodeAudioData(data))
        .then(audio => {
            const src = ctx.createBufferSource()
            src.buffer = audio
            src.connect(ctx.destination)
            src.onended = () => ctx.close()
            src.start()
        })

}

const readingPath = "/api/v1/readings"

export interface SessionConfigVersioned<T = RecognitionProfile> {
    version: number,
    sessionConfig: SessionConfig<T>
}

type ConnectionControl = "connect" | "disconnect" | "failure"
const connectWs = (user: string,
                   token: string,
                   library: string | undefined,
                   resource: string | undefined,
                   traineeId: string,
                   sessionId: string | undefined,
                   record: boolean,
                   onMessage: (messsage: ReadingUpdate) => void,
                   onStats: (stats: ReadingStats) => void,
                   onSessionConfig: (sessionConfig: SessionConfigVersioned) => void,
                   onClose: () => void,
                   onComplete: () => void,
                   onSessionInfo: (sessionInfo: SessionInfo) => void,
                   onConnectionQuality: (connectionQuality: number) => void) => {
    const protocol = process.env.REACT_APP_PROTOCOL === "http" ? "ws" : "wss"
    const sessionIdParam = sessionId ? `&session=${sessionId}` : ""
    const recordParam = record ? `&record=true` : ""
    let connectionQuality = 10
    return new Promise<WebSocket>((resolve, reject) => {
        let conn = new WebSocket(`${protocol}://${process.env.REACT_APP_BACKEND}${readingPath}/read/${library}/${resource}?user=${user}&trainee=${traineeId}${sessionIdParam}${recordParam}`)

        conn.onopen = (d) => {
            conn.send(token)
            resolve(conn)
        }
        conn.onerror = error => {
            console.log(error)
            reject(error)
        }
        conn.onmessage = event => {
            if (typeof event.data === "string") {
                const message = JSON.parse(event.data) as InputMessage
                const messageType = message.type
                switch (messageType) {
                    case "complete":
                        onComplete()
                        break
                    case "reading-update":
                        onMessage(message)
                        break
                    case "latency-check":
                        const latency = Date.now() - message.timestamp
                        conn.send(JSON.stringify(TimeSync(latency)))
                        connectionQuality = 0.9 * connectionQuality + 0.1 * latency;
                        onConnectionQuality(connectionQuality)
                        break
                    case "info":
                        console.log(message.message);
                        break
                    case "session-info":
                        onSessionInfo(message)
                        break
                    case "stats":
                        onStats(message)
                        break
                    case "session-config-announcement":
                        onSessionConfig({version: message.version, sessionConfig: message.config})
                        break;
                    default:
                        throw new UnreachableCaseError(messageType)
                }
            } else play(event.data)
        }
        conn.onclose = event => {
            console.log("Closing socket...", event)
            onClose()
        }
    })
}

interface ReadingProps {
    library: string
    readingTitle: string
    record: string
}

interface MessageSender {
    sendMessage(message: Messaging.OutputMessage): void

    sendData(data: ArrayBufferLike): void

    close(): void
}

const Reading: React.FC = () => {
    //context
    const userContext = React.useContext(AuthenticationContext)
    const traineeContext = React.useContext(TraineeContext)
    const cRef: React.RefObject<HTMLSpanElement> = React.createRef<HTMLSpanElement>()

    //url params
    const params = useParams<keyof ReadingProps>()
    const [searchParams] = useSearchParams()
    const record = searchParams.has("record")
    //navigation
    const navigate = useNavigate()

    //state
    //controls webscoket connection:
    // "connect" - should initiate connection
    // "disconnect" - intended disconnection
    // "failure" - unintended disconnection (error, or server disconnected)
    const [connect, setConnect] = useState<ConnectionControl>("connect")
    //set to true when the signal "reading complete" has been received
    const [complete, setComplete] = useState(false)
    //keeps current session id received from server
    const sessionInfo = useRef<SessionInfo>()
    //current reading content
    const [reading, setReading] = useState<ReadingUpdate>()
    //current web socket connection
    const [ws, setWs] = useState<MessageSender>()
    //current audio sink (sends to current websocket connection)
    const [micOutput, setMicOutput] = useState<(data: any) => void>()
    //current stats
    const [stats, setStats] = useState<ReadingStats>()
    //current audio source
    const [microphone, setMicrophone] = useState<MicrophoneDevice>(defaultMicrophone)
    //controls showing questions dialog
    const [showQuestions, setShowQuestions] = useState(false)
    //controls showing setting dialog
    const [settingOpen, setSettingOpen] = useState(false)

    const [sessionConfig, setSessionConfig] = useState<SessionConfigVersioned>()

    const [connectionQuality, setConnectionQuality] = useState(0)


    const [volume, setVolume] = useState(0);
    const onVolume = useMemo(() => {
        const subject = new Subject<number>()
        subject.pipe(throttleTime(200)).subscribe(setVolume)
        return subject
    }, [])
    const onComplete = () => {
        console.log("Completing...")
        setConnect("disconnect")
        setComplete(true)
        console.log("Completed!")
    }
    const sentenceProgress = () => {
        if (stats?.sentenceTime && stats.sentenceLength) {
            return stats.sentenceTime * 100 / stats.sentenceLength
        } else return 0
    }
    const totalTime = () => {
        if (stats?.scriptTime) {
            let minutes = Math.floor(stats.scriptTime / 60)
            let seconds = stats.scriptTime % 60
            return minutes + (seconds > 9 ? ":" : ":0") + seconds
        } else return "00:00"
    }

    const keyboardShortcutsHandler = useCallback<(sender: MessageSender, evt: KeyboardEvent) => void>((sender, event) => {
        switch (event.code) {
            case 'Space':
            case 'ArrowRight':
            case 'ArrowDown':
                sender.sendMessage(Messaging.Forward)
                break;
            case 'Backspace':
            case 'ArrowLeft':
            case 'ArrowUp':
                sender.sendMessage(Messaging.Rewind)
        }
    }, [])

    useEffect(() => {
        if (connect !== "connect") return //will return but first will run the previous finalizer
        const user = userContext.user()
        const trainee = traineeContext.trainee()
        const connPromise = user.getIdToken().then(token =>
            connectWs(user.email, token, params.library, params.readingTitle, trainee.id, sessionInfo.current?.sessionId, record,
                setReading, setStats, setSessionConfig, () => {setConnect((prev) => prev === "connect" ? "failure" : "disconnect")},
                onComplete, (value) => sessionInfo.current = value, setConnectionQuality
            )).then(conn => {
            const sender: MessageSender = {
                close(): void {
                    console.log("Closing sender")
                    conn.close()
                },
                sendData(data: ArrayBufferLike): void {
                    conn.send(data)
                },
                sendMessage(message: Messaging.OutputMessage): void {
                    conn.send(JSON.stringify(message))
                }
            }
            const newMicOutput = (data: any) => sender.sendData(data)

            setWs(sender)
            setMicOutput((_: any) => newMicOutput)
            const keyListener: (event: KeyboardEvent) => void = event => {
                // event.stopPropagation()
                event.preventDefault()
                keyboardShortcutsHandler(sender, event)
            }
            document.addEventListener("keydown", keyListener, {capture: true})
            return {conn, keyListener}
        })

        return () => {
            console.log("Executing shutdown...")
            connPromise.then(({conn, keyListener}) => {
                conn.close()
                document.removeEventListener("keydown", keyListener, {capture: true})
                setWs(undefined)
                setMicOutput(undefined)
            })
        }

    }, [connect, userContext, traineeContext, params.readingTitle, params.library, keyboardShortcutsHandler, sessionInfo, record])

    useEffect(() => cRef.current?.scrollIntoView({behavior: 'smooth', block: 'center'}))
    const closeSettings = useCallback(() => setSettingOpen(false), [])
    const connectionQualityPrct = Math.min(100, Math.max(0, Math.round(130-connectionQuality)))
    const finish = (
        <>
            <p style={{color: "black", fontSize: "4vw"}}>Brawo ukończyłeś czytankę!</p>
            <p style={{color: "black", fontSize: "4vw"}}>Co chcesz zrobić teraz?</p>
            {showQuestions && sessionInfo.current &&
                <SessionEdit sessionId={sessionInfo.current.sessionId} onClose={() => navigate("/")} onSave={() => navigate("/")}/>}
            <Button variant="outlined" onClick={() => navigate("/")}>Zakończ</Button>&nbsp;&nbsp;
            <Button variant="outlined" onClick={() => setShowQuestions(true)}>Wprowadź ocenę</Button>
        </>
    )
    return (
        <>
            <MicCapture microphone={microphone} output={micOutput} onVolume={onVolume}/>
            <SessionConfigDialog sessionConfig={{
                current: sessionConfig,
                onChange: (newS: SessionConfigVersioned) => ws?.sendMessage(SessionConfigUpdate(newS.version, newS.sessionConfig))
            }}
                                 microphone={{current: microphone, onChange: setMicrophone}} open={settingOpen}
                                 onClose={closeSettings}></SessionConfigDialog>
            <SessionRestartDialog open={connect === "failure" && !complete} onRestart={() => setConnect("connect")}
                                  onCancel={() => {setConnect("disconnect")}}></SessionRestartDialog>
            <AppBar>
                <Toolbar sx={{columnGap: 1}}>
                    <Button disabled={complete} onClick={() => setSettingOpen(true)}><SettingsIcon color="action"/></Button>
                    <ButtonGroup disabled={connect !== "connect"}>
                        <Button onClick={() => ws?.sendMessage(Messaging.PrevSentence)}><SkipPrevious/></Button>
                        <Button onClick={() => ws?.sendMessage(Messaging.Rewind)}><FastRewind/></Button>
                        <Button onClick={() => ws?.sendMessage(Messaging.Read)}><Hearing/></Button>
                        <Button onClick={() => ws?.sendMessage(Messaging.Forward)}><FastForward/></Button>
                        <Button onClick={() => ws?.sendMessage(Messaging.NextSentence)}><SkipNext/></Button>
                    </ButtonGroup>
                    <MuiToggleButton
                        value="ignored"
                        sx={{height: 36}} //match other buttons height
                        color="secondary"
                        selected={connect !== "connect"}
                        onChange={() => setConnect((connect === "connect") ? "disconnect" : "connect")}
                    >
                        <MicOffIcon/>
                    </MuiToggleButton>
                    <Tooltip title={`Jakość połączenia: ${connectionQualityPrct}%`}>
                    {
                        connectionQualityPrct < 25 ? <SignalCellular1BarRoundedIcon color="info"/> :
                            connectionQualityPrct < 50 ? <SignalCellular2BarRoundedIcon color="info"/> :
                                connectionQualityPrct < 75 ? <SignalCellular3BarRoundedIcon color="info"/> :
                                    <SignalCellular4BarRoundedIcon color="info"/>
                    }
                    </Tooltip>
                    <Tooltip title={`Głośność: ${Math.min(100, Math.round(volume/10))}%`}>
                    {
                        volume < 300 ? <SignalWifi1BarRoundedIcon color="secondary" style={{transform: "rotate(90deg)"}}/> :
                            volume < 500 ? <SignalWifi2BarRoundedIcon color="secondary" style={{transform: "rotate(90deg)"}}/> :
                                volume < 1200 ? <SignalWifi3BarRoundedIcon color="secondary" style={{transform: "rotate(90deg)"}}/> :
                                    <SignalWifi4BarRoundedIcon color="secondary" style={{transform: "rotate(90deg)"}}/>
                    }
                    </Tooltip>
                    <Box sx={{flexGrow: 1}}/>
                    <Link
                        underline="hover"
                        component="button"
                        onClick={() => navigate("/")}
                    >
                        Zakończ
                    </Link>
                </Toolbar>
            </AppBar>
            <Toolbar/>
            <Box component="main" sx={{
                flexGrow: 1,
                padding: 5,
                fontFamily: 'Helvetica, Arial, sans-serif',
                background: (connect !== "connect" && !complete) ? grey["400"] : undefined
            }}>
                {complete ? finish : <ReadingContent content={reading} ref={cRef}/>}
                <Box height={"50vh"} flexDirection={"column"} sx={{flexGrow: 10}}/>
                <Toolbar/>
            </Box>
            <AppBar position="fixed" color="primary" sx={{top: 'auto', bottom: 0}}>
                <Box sx={{width: '100%'}}>
                    {sessionConfig?.sessionConfig.timeLimit &&
                        <LinearProgress hidden={!sessionConfig.sessionConfig.timeLimit} color={"secondary"} variant="determinate" value={sentenceProgress()}/>}
                </Box>
                <Toolbar>
                    <Medals done={reading?.done || 0} total={reading?.total || 0}/>
                    <Box sx={{flexGrow: 1}}/>
                    <Typography variant="h5" color={"gray"}>{totalTime()}</Typography>
                </Toolbar>
            </AppBar>
        </>
    )
}

export default Reading;
