import React, {useEffect, useState} from "react";
import {Box, CircularProgress, MenuItem, Select, Link} from "@mui/material";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import {DataGrid, GridActionsCellItem, GridColDef, GridRenderCellParams, GridToolbarQuickFilter} from "@mui/x-data-grid";
import DefaultAppBar from "../DefaultAppBar";
import {AuthenticationContext} from "../AuthenticationContext";
import {GridRowParams} from "@mui/x-data-grid/models/params/gridRowParams";
import {GridActionsColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import {Voicemail} from "@mui/icons-material";

interface ReadingItem {
    library: string,
    id: string,
    title: string,
    countSentences: number,
    countWords: number,
    excerpt: string
}

interface Library {
    id: string,
    description: string,
    readings: ReadingItem[]
}

interface LibrariesResponse {
    libraries: Library[]
}

const ReadingsList: React.FC = () => {
    const quickSearchToolbar = () =>
        <Box>
            <GridToolbarQuickFilter variant={"outlined"} placeholder="Szukaj"/>&nbsp;&nbsp;
            <Select value={selectedLibrary} onChange={(event) => setSelectedLibrary(event.target.value)}>
                {libraries.map((library, idx) => <MenuItem key={idx} value={library.id}>{library.description}</MenuItem>)}
            </Select>
        </Box>
    const userContext = React.useContext(AuthenticationContext)


    const [libraries, setLibraries] = useState<Library[]>([])
    const [selectedLibrary, setSelectedLibrary] = useState("")
    const navigate = useNavigate()

    useEffect(() => {
        userContext.client().get<LibrariesResponse>(`readings/list`).then(({data}) => {
            setLibraries(data.libraries)
            setSelectedLibrary(data.libraries[0]?.id || "")
        })
    }, [userContext])

    const columns: () => (GridColDef | GridActionsColDef)[] = () => {
            const titleColumn = {
                field: 'title',
                headerName: 'Tytuł',
                flex: 1,
                minWidth: 150,
                renderCell: (params: GridRenderCellParams<string>) => (
                    <Link color="secondary" underline="hover" component={RouterLink} to={`/reading/${selectedLibrary}/${params.row.id}`}>{params.value}</Link>
                ),
            }
            const countSentencesColumn = {field: 'countSentences', headerName: 'Zdań'}
            const countWordsColumn = {field: 'countWords', headerName: 'Słów'}
            const excerptColumn = {field: 'excerpt', headerName: 'Przykład', flex: 5}
            if (userContext.user().roles.includes("therapist") || userContext.user().roles.includes("admin")) {
                const actionColumns = {
                    field: 'actions',
                    type: 'actions',
                    width: 30,
                    getActions: (params: GridRowParams<ReadingItem>) => [
                        <GridActionsCellItem
                            icon={<Voicemail/>}
                            label="Nagraj sesję"
                            onClick={() => navigate(`/reading/${selectedLibrary}/${params.row.id}?record`)}
                        />
                    ]
                }
                return [titleColumn, actionColumns, countSentencesColumn, countWordsColumn, excerptColumn]
            } else return [titleColumn, countSentencesColumn, countWordsColumn, excerptColumn]
        }
    ;


    const readings = libraries.find((library) => library.id === selectedLibrary)?.readings || []
    const content = readings ?
        <DataGrid pageSize={100} density='compact' rows={readings} columns={columns()}
                  sx={{
                      border: 0,
                  }}
                  initialState={{
                      sorting: {
                          sortModel: [{field: 'title', sort: 'asc'}],
                      },
                  }}
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  components={{Toolbar: quickSearchToolbar}}
        /> :
        <Box sx={{display: 'flex', justifyContent: 'center'}}>

            <CircularProgress/>
        </Box>


    return (
        <Box sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <DefaultAppBar>
                <Link
                    underline="hover"
                    component="button"
                    onClick={() => navigate("/sessions")}
                >
                    Historia
                </Link>
            </DefaultAppBar>

            <Box sx={{padding: 1, flexGrow: 2}}>
                {content}
            </Box>
        </Box>
    )
}

export default ReadingsList
