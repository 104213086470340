import React from "react"
import MedalGray from "./medal_grey.svg"
import Medal from "./medal.svg"
import {Box} from "@mui/material"

interface MedalsProps{
  done: number
  total: number
}
const Medals: React.FC<MedalsProps> = props => {
  return(
  <Box>
    {Array(props.total).fill(0).map((_,idx)=>{
      if (idx<props.done)
      return (<img key={idx} src={Medal} width="30"/>)
      else
       return (<img key={idx} src={MedalGray} width="30"/>)
    })}
  </Box>
  );
}
export default Medals;
