import React from "react";
import {Login} from "./Login";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";

export type UserRole = "user" | "therapist" | "admin"
export interface ApplicationUser {
    readonly emailVerified: boolean
    readonly email: string
    readonly uid: string
    readonly roles: UserRole[]

    getIdToken(forceRefresh?: boolean): Promise<string>
    hasAnyRole(...role: UserRole[]): Boolean
}

interface Client {
    get<T = any>(url: string): Promise<AxiosResponse<T>>

    patch<D, T = any>(url: string, body: D): Promise<AxiosResponse<T>>
    post<D, T = any>(url: string, body: D): Promise<AxiosResponse<T>>
    put<D, T = any>(url: string, body: D): Promise<AxiosResponse<T>>
}

const context = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BACKEND}/api/v1`

function execute<T, D = any>(user: ApplicationUser, config: AxiosRequestConfig<D>): Promise<AxiosResponse<T>> {
    return user?.getIdToken().then(token =>
        axios.request<T>({
            headers: {
                Authorization: `Bearer ${token}`,
            },
            ...config,
        }))
}

const buildClient: (user: ApplicationUser) => Client = (user: ApplicationUser) => ({
    get<T = any>(url: string): Promise<AxiosResponse<T>> {
        return execute(user, {
            method: "GET",
            url: `${context}/${url}`
        })
    },
    patch<D, T = any>(url: string, body: D): Promise<AxiosResponse<T>> {
        return execute(user, {
            method: "PATCH",
            url: `${context}/${url}`,
            data: body
        })
    },
    post<D, T = any>(url: string, body: D): Promise<AxiosResponse<T>> {
        return execute(user, {
            method: "POST",
            url: `${context}/${url}`,
            data: body
        })
    },
    put<D, T = any>(url: string, body: D): Promise<AxiosResponse<T>> {
        return execute(user, {
            method: "PUT",
            url: `${context}/${url}`,
            data: body
        })
    }
})

interface Client {
    get<T = any>(url: string): Promise<AxiosResponse<T>>

    patch<D, T = any>(url: string, body: D): Promise<AxiosResponse<T>>

    post<D, T = any>(url: string, body: D): Promise<AxiosResponse<T>>

    put<D, T = any>(url: string, body: D): Promise<AxiosResponse<T>>
}

interface UserContext {
    user(): ApplicationUser

    client(): Client

    logOut(): void
}


const defaultUserContext: UserContext = {
    client(): Client {
        throw new Error("User not logged-in!")
    },
    user: () => {
        throw new Error("User not logged-in!")
    },
    logOut: () => {
    }
}

const AuthenticationContext = React.createContext<UserContext>(defaultUserContext);

interface Props {
    children: React.ReactNode;
}

const UserContextProvider: React.FC<Props> = ({children}) => {
    const [user, setUser] = React.useState<ApplicationUser | null>(null);
    const userContext = (user: ApplicationUser) => ({
        user: () => user,
        logOut: () => setUser(null),
        client: () => buildClient(user)
    })

    return user ? (
        <AuthenticationContext.Provider value={userContext(user)}>
            {children}
        </AuthenticationContext.Provider>
    ) : (<Login setUser={setUser}/>)

}


export {AuthenticationContext, UserContextProvider};