import React from "react";
import {AuthenticationContext} from "./AuthenticationContext";
import {AppBar, Box, Link, Toolbar, Typography} from "@mui/material";
import {TraineeContext} from "./trainee/TraineeContext";
import SettingsIcon from "@mui/icons-material/Settings";
import {Autorenew, ManageAccounts} from "@mui/icons-material";
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import Dropdown from '@mui/joy/Dropdown';

interface Props {
    children: React.ReactNode;
}

const DefaultAppBar: React.FC<Props> = ({children}) => {
    const userContext = React.useContext(AuthenticationContext)
    const traineeContext = React.useContext(TraineeContext)
    const trainee = traineeContext.trainee()

    return (
        <AppBar position='static'>
            <Toolbar sx={{gap: 1}}>
                <Dropdown>
                    <MenuButton>
                        <ManageAccounts/> <Typography variant="body1">{trainee.name}</Typography>
                    </MenuButton>
                    <Menu color={"primary"}>
                        <MenuItem onClick={() => traineeContext.close()}>
                            <Autorenew/>Zmień ucznia
                        </MenuItem>
                        <MenuItem onClick={() => traineeContext.editProfile()}>
                            <SettingsIcon/> Ustawienia
                        </MenuItem>
                    </Menu>
                </Dropdown>
                {children}
                <Box sx={{flexGrow: 1}}/>
                <Link
                    underline="hover"
                    component="button"
                    onClick={userContext.logOut}
                >
                    Wyloguj
                </Link>
            </Toolbar>
        </AppBar>
    )
}

export default DefaultAppBar
