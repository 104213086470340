import React from 'react';
import './index.css';
import {AuthenticationContext} from "./AuthenticationContext";
import Reading from "./reading/Reading";
import {createBrowserRouter, RouterProvider, Navigate} from "react-router-dom";
import ReadingsList from "./reading/ReadingsList";
import {TraineeContextProvider} from "./trainee/TraineeContext";
import {SessionsList} from "./trainee/SessionsList";
import {Registered} from "./Registered";

const userRouter = createBrowserRouter([
    {path: "", element: <Navigate replace to="readings"/>},
    {path: "readings", element: <ReadingsList/>},
    {path: "sessions", element: <SessionsList/>},
    {path: "reading/:library/:readingTitle", element: <Reading/>},
]);

const userRoutes = (
    <TraineeContextProvider>
        <RouterProvider router={userRouter}/>
    </TraineeContextProvider>
)

const nonUserRouter = createBrowserRouter([
    {path: "", element: <Navigate replace to="registered"/>},
    {path: "registered", element: <Registered/>},
]);

const nonUserRoutes = <RouterProvider router={nonUserRouter}/>

export const ReadingRoutes: React.FC = () => {
    const user = React.useContext(AuthenticationContext).user()

    return user.roles.includes("user") ? userRoutes : nonUserRoutes
}

