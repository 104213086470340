import React, {forwardRef} from "react";
import {ReadingUpdate, UnreachableCaseError} from "./Messaging";

const prev_sentence_style = {
    color: "gray",
    fontSize: "3vw"
}
const next_sentence_style = {
    color: "gray",
    fontSize: "3vw"
}
const prev_words_style = {
    color: "gray",
    fontSize: "4vw"
}
const current_words_style = {
    color: "black",
    fontSize: "4vw"
}
const next_words_style = {
    color: "gray",
    fontSize: "4vw"
}
const hyphen_even_style = {
    color: "black",
    fontSize: "4vw"
}
const hyphen_odd_style = {
    color: "crimson",
    fontSize: "4vw"
}
interface Props {
    content?: ReadingUpdate
}

export const ReadingContent: React.ForwardRefExoticComponent<Props & React.RefAttributes<HTMLSpanElement>> = forwardRef((props: Props, cRef:React.ForwardedRef<HTMLSpanElement>) => {
    const fragments = props.content
    if (fragments) {
        const elements =  fragments.fragments.map((f, idx) => {
                const style = f.style
                switch (style) {
                    case "prev-sentence":
                        return <p key={idx} style={prev_sentence_style}>{f.text}</p>
                    case "next-sentence":
                        return <p key={idx} style={next_sentence_style}>{f.text}</p>
                    case "prev-words":
                        return <span key={idx} style={prev_words_style}>{f.text}</span>
                    case "current-words":
                        return <span key={idx} ref={cRef} style={current_words_style}>{f.text}</span>
                    case "hyphen":
                        const hyphens = f.text.map((h, idx2) =>
                            idx2 % 2 === 0 ? <span key={idx2} style={hyphen_even_style}>{h}</span> :
                                <span key={idx2} style={hyphen_odd_style}>{h}</span>
                        );
                        return <span key={idx} ref={cRef} style={current_words_style}>{hyphens}</span>
                    case "next-words":
                        return <span key={idx} style={next_words_style}>{f.text}</span>
                    default:
                        throw new UnreachableCaseError(style)
                }
            }
        )
        return <> {elements} </>
    } else
        return <p key="x">Loading...</p>
})