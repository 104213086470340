type OutputMessageType = "time-sync" | "latency-check" | "forward" | "read" | "rewind" | "next-sentence" | "prev-sentence" | "recognition-params-patch" | "session-config-update"

export interface OutputMessage {
    type: OutputMessageType
}

export type InputMessage = ReadingUpdate | LatencyCheck | Info | Complete | SessionInfo | ReadingStats | SessionConfigAnnouncement

////////////////////////////////////////////////////////////////////
//      Input messages
////////////////////////////////////////////////////////////////////
export type Fragment = RegularFragment | Hyphen

export interface RegularFragment {
    style: "prev-sentence" | "next-sentence" | "prev-words" | "current-words" | "next-words"
    text: string
}

export interface Hyphen {
    style: "hyphen"
    text: string[]
}

export interface ReadingUpdate {
    type: "reading-update"
    total: number
    done: number
    fragments: Fragment[]
}

export interface LatencyCheck {
    type: "latency-check"
    timestamp: number
}

export interface Info {
    type: "info"
    message: string
}

export interface Complete {
    type: "complete"
}

export interface SessionInfo {
    type: "session-info"
    sessionId: string
}

export interface ReadingStats {
    type: "stats"
    sentenceLength: number
    wordTime: number | null
    sentenceTime: number
    scriptTime: number
    audioDelay: number
}

export interface SessionConfigAnnouncement {
    type: "session-config-announcement"
    version: number
    config: SessionConfig
}

export interface SessionConfig<T = RecognitionProfile> {
    timeLimit: boolean
    onlyUpperCase: boolean
    recognitionProfile:T
}

export type RecognitionProfile = GCPSpeechToTextV1

export function isGCPV1(config: SessionConfig): config is SessionConfig<GCPSpeechToTextV1> {
    return config.recognitionProfile.type === "GCPV1"
}

export type  GCPSpeechToTextV1 = {
    useSubstitutions: boolean
    partialMatch: boolean
    modelAdaptation: boolean
    boost: number
    type: "GCPV1"
}

////////////////////////////////////////////////////////////////////
//      Output messages
////////////////////////////////////////////////////////////////////

class MessageImpl implements OutputMessage {
    type: OutputMessageType

    constructor(type: OutputMessageType) {
        this.type = type
    }
}

export const Forward = new MessageImpl("forward")
export const Rewind = new MessageImpl("rewind")
export const Read = new MessageImpl("read")
export const PrevSentence = new MessageImpl("prev-sentence")
export const NextSentence = new MessageImpl("next-sentence")

export function TimeSync(time: number): OutputMessage {
    return {
        type: "time-sync",
        time
    } as OutputMessage
}

export function SessionConfigUpdate(oldVersion:number, newConfig: SessionConfig): OutputMessage {
    return {
        oldVersion,
        newConfig,
        type: "session-config-update"
    } as OutputMessage
}

////////////////////////////////////////////////////////////////////
//      Utils
////////////////////////////////////////////////////////////////////
export class UnreachableCaseError extends Error {
    constructor(val: never) {
        super(`Unreachable case: ${JSON.stringify(val)}`);
    }
}